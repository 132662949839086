import { Link } from "gatsby";
import React, { useState } from "react";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav className="bg-yellow">
      <div className="flex flex-wrap items-center justify-between mx-auto p-4 md:p-8">
        <Link to="/" className="flex items-center no-underline text-grey-dark">
          <svg
            className="fill-current h-6 mr-2 w-32"
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="105"
            viewBox="0 0 800 105"
          >
            <defs>
              <clipPath id="b">
                <rect width="800" height="105" />
              </clipPath>
            </defs>
            <g id="a" clipPath="url(#b)">
              <g transform="translate(0 0.024)">
                <path
                  d="M42.106,105.263V84.21H63.158v21.053Zm-21.053,0V84.21H42.106v21.053ZM0,105.263V84.21H21.053v21.053ZM0,84.21V63.158H21.053V84.21ZM0,63.158V42.106H21.053V63.158ZM0,42.106V21.053H21.053V42.106ZM0,21.053V0H21.053V21.053Z"
                  transform="translate(189.474)"
                />
                <g transform="translate(0 0)">
                  <path
                    d="M29.053-26.947H8V-48H29.053Z"
                    transform="translate(-8 48)"
                  />
                  <path
                    d="M37.053-26.947H16V-48H37.053Z"
                    transform="translate(5.053 48)"
                  />
                  <path
                    d="M37.053-18.947H16V-40H37.053Z"
                    transform="translate(5.053 61.053)"
                  />
                  <path
                    d="M37.053-10.947H16V-32H37.053Z"
                    transform="translate(5.053 74.105)"
                  />
                  <path
                    d="M37.053-2.947H16V-24H37.053Z"
                    transform="translate(5.053 87.158)"
                  />
                  <path
                    d="M37.053,5.053H16V-16H37.053Z"
                    transform="translate(5.053 100.211)"
                  />
                  <path
                    d="M45.053,5.053H24V-16H45.053Z"
                    transform="translate(18.105 100.211)"
                  />
                  <path
                    d="M29.053,5.053H8V-16H29.053Z"
                    transform="translate(-8 100.211)"
                  />
                  <path
                    d="M45.053-26.947H24V-48H45.053Z"
                    transform="translate(18.105 48)"
                  />
                </g>
                <path
                  d="M63.158,105.263V84.21H84.21v21.053ZM0,105.263V84.21H21.053v21.053ZM63.158,84.21V63.158H84.21V84.21ZM0,84.21V63.158H21.053V84.21ZM42.106,63.158V42.106H63.158V63.158Zm-21.053,0v0H0v-42.1H21.053V42.106H42.106V63.158Zm42.1-21.052V21.053H84.21V42.106ZM42.106,21.053V0H63.158V21.053Zm-21.053,0V0H42.106V21.053ZM0,21.053V0H21.053V21.053Z"
                  transform="translate(84.211)"
                />
              </g>
              <g transform="translate(294.737 0.024)">
                <path
                  d="M42.106,105.263V84.21H63.158v21.053Zm-21.053,0V84.21H42.1v21.053Zm42.1-21.053V63.158H84.21V84.21ZM0,84.21V63.158H21.053V84.21ZM0,63.158V42.106H21.053V63.158ZM63.158,42.106V21.053H84.21V42.106ZM0,42.106V21.053H21.053V42.106ZM42.106,21.053V0H63.158V21.053Zm-21.053,0V0H42.1V21.053Z"
                  transform="translate(315.79)"
                />
                <path
                  d="M63.158,105.263V84.21H84.21v21.053ZM0,105.263V84.21H21.053v21.053ZM63.158,84.21v-42.1H84.21v42.1ZM0,84.21V63.158H21.053V84.21ZM42.106,63.158V42.106H63.158V63.158Zm-21.053,0V42.106H42.106V63.158ZM0,63.158V42.106H21.053V63.158ZM63.158,42.106V0H84.21V42.106ZM0,42.106V21.053H21.053V42.106ZM0,21.053V0H21.053V21.053Z"
                  transform="translate(421.053)"
                />
                <path d="M84.21,105.263V84.21h21.053v21.053Zm-84.21,0V84.21H21.053v21.053ZM84.21,84.21V63.158h21.053V84.21ZM0,84.21V63.158H21.053V84.21ZM84.21,63.158V42.106h21.053V63.158Zm-42.1,0V42.106H63.158V63.158ZM0,63.158V42.106H21.053V63.158ZM84.21,42.106V21.053h21.053V42.106Zm-21.053,0V21.053H84.21V42.106Zm-42.1,0V21.053H42.106V42.106ZM0,42.106V21.053H21.053V42.106ZM84.21,21.053V0h21.053V21.053ZM0,21.053V0H21.053V21.053Z" />
                <path
                  d="M42.106,105.263V84.21H63.158v21.053Zm-21.053,0V84.21H42.106v21.053ZM0,105.263V84.21H21.053v21.053ZM0,84.21V63.158H21.053V84.21ZM42.106,63.158V42.106H63.158V63.158Zm-21.053,0V42.106H42.106V63.158ZM0,63.158V42.106H21.053V63.158ZM0,42.106V21.053H21.053V42.106ZM42.106,21.053V0H63.158V21.053Zm-21.053,0V0H42.106V21.053ZM0,21.053V0H21.053V21.053Z"
                  transform="translate(126.316)"
                />
                <path
                  d="M63.158,105.263V84.21H84.21v21.053ZM0,105.263V84.21H21.053v21.053ZM63.158,84.21V63.158H84.21V84.21ZM0,84.21V63.158H21.053V84.21ZM42.106,63.158V42.106H63.158V63.158Zm-21.053,0v0H0v-42.1H21.053V42.106H42.106V63.158Zm42.1-21.052V21.053H84.21V42.106ZM42.106,21.053V0H63.158V21.053Zm-21.053,0V0H42.106V21.053ZM0,21.053V0H21.053V21.053Z"
                  transform="translate(210.526)"
                />
              </g>
            </g>
          </svg>
        </Link>

        <button
          className="block md:hidden flex items-center px-3 py-2 rounded text-grey-dark"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          <div className="text-sm uppercase font-bold">
            <Link
              to="/"
              className="block mt-4 md:inline-block md:mt-0 mr-6 no-underline"
            >
              Home
            </Link>

            <Link
              to="/services"
              className="block mt-4 md:inline-block md:mt-0 mr-6 no-underline"
            >
              Services
            </Link>

            <Link
              to="/projects"
              className="block mt-4 md:inline-block md:mt-0 mr-6 no-underline"
            >
              Projects
            </Link>

            <Link
              to="/clients"
              className="block mt-4 md:inline-block md:mt-0 mr-0 no-underline"
            >
              Clients
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
