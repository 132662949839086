import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";

function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="flex flex-col font-sans min-h-screen text-gray-900">
          <Header siteTitle={data.site.siteMetadata.title} />

          <div className="flex flex-col flex-1 md:justify-center mx-auto w-full">
            {children}
          </div>

          <footer className="bg-grey-dark text-xs ">
            <div className="flex justify-between mx-auto p-4 md:p-8">
              <p className="text-grey-light opacity-50 flex-grow">
                <span>
                  General enquiries:{" "}
                  <a href="mailto&#58;hello&#64;%&#54;9rl&#109;erc&#104;&#46;&#37;63o&#109;">
                    hello&#64;irlmerc&#104;&#46;c&#111;&#109;
                  </a>
                </span>
              </p>

              <p className="text-grey-light opacity-50 mx-4">
                <span>Reg. 09041398. VAT 187704573 © IRL Digital Ltd.</span>
              </p>

              <p className="text-grey-light">
                <a
                  href="https://privacy.irlmerch.com/"
                  className="no-underline"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </footer>
        </div>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
