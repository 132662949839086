import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <div className="bg-yellow flex justify-center min-h-screen">
        <h2 className="font-display text-center text-4xl my-8 p-3">
          404<br />
          Page not found...<br />
          😿
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;

export function Head() {
  return (
    <SEO title="404: Not found" />
  )
}
