import * as React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

function SEO({ title, description }) {
  return (
    <StaticQuery
      query={detailsQuery}
      metaTitle={title}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description;
        const metaTitle = `${title} | ${data.site.siteMetadata.title}`;
        const author = data.site.siteMetadata.author;
        return (
          <>
            <title>{metaTitle}</title>
            <meta name="description" content={`${metaDescription}`} />
            <meta name="og:title" content={`${metaDescription}`} />
            <meta name="og:description" content={`${metaDescription}`} />
            <meta name="og:type" content={`website`} />
            <meta name="twitter:card" content={`summary`} />
            <meta name="twitter:creator" content={`${author}`} />
            <meta name="twitter:title" content={`${metaTitle}`} />
            <meta name="twitter:description" content={`${metaDescription}`} />
          </>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
